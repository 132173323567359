<template lang="pug">
  content-layout.help-view(v-if="selectedTab")
    template(v-slot:title-block-left)
      p.text-averta-light-32 {{ $t('FAQ') }}

    template(v-slot:nav-bar-container)
      div.scroll-horizontal
        div.nav-bar.col-9(ref="navbar")
          button.button.nav(v-for="(tab, index) in showedTabs" :id="tab.name" :key="index" :class="{selected : selectedTab.name == tab.name}" @click="selectedTab = tab" v-text="tab.label" :ref="'navbar_button_' + index")
          div.more(v-if="hiddenTabs.length>0")
            button.button.circle-icon( @mouseover="showHidden = true")
              img(src="./../assets/icon/create-icon.svg")
            div.hidden-tab-wrapper(v-if="showHidden" @mouseleave="showHidden = false" v-click-outside="hide")
              p.text-averta-regular-20(v-for="(tab, index) in hiddenTabs" :key="index" :class="{selected : selectedTab.name == tab.name}" @click="selectedTab = tab" v-text="tab.label")


    template(v-slot:main-content)
      component.content(:is="selectedTab.component")

</template>

<script lang="ts">
import Vue from 'vue'
import Help from './../applicationSettings/help.js'

export default Vue.extend({

  components: {
    ContentLayout: () => import('skillbase-component/src/components/common/layout/ContentLayout.vue')
  },

  data() {
    return {
      selectedTab: null,
      showedTabs: [],
      hiddenTabs: [],
      showHidden: false,
      tabs: [
        {
          label: this.$t('general'),
          name: "general",
          component: Help.general
        }, {
          label: this.$t('profil'),
          name: "profil",
          component: Help.profile
        }, {
          label: this.$t('groups'),
          name: "group",
          component: Help.group
        }, {
          label: this.$t('requests'),
          name: "request",
          component: Help.request
        }, {
          label: this.$t('articles'),
          name: "article",
          component: Help.article
        }, {
          label: this.$t('contact.help'),
          name: "contact",
          component: Help.contact
        }, {
          label: this.$t('copilot'),
          name: "copilot",
          component: Help.copilot
        }, {
          label: this.$t('privacy.notice'),
          name: "privacy",
          component: Help.privacyNotice
        }
      ]
    }
  },

  mounted() {
    this.selectedTab = this.tabs[0]
  },

  watch: {
    tabs: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.calculateDisplayTabs(newValue)
        }
      }
    }
  },

  methods: {
    hide() {
      this.showHidden = false;
    },

    async calculateDisplayTabs(tabs) {
      this.showedTabs = []
      this.hiddenTabs = []

      for (let j = 0; j < tabs.length; j++) {
        let tab = tabs[j]

        if (j < tabs.length - 1) {
          this.showedTabs.push(tab)
        } else {
          this.hiddenTabs.push(tab)
        }

        await this.$nextTick()
      }
    },
  }

})
</script>

<style lang="scss" scoped>
.help-view {
  .hidden-tab-wrapper {
    position: absolute;
    top: 45px;
    box-shadow: 0px 3px 6px var(--shadow-color);
    border-radius: 10px;
    width: max-content;
    background-color: var(--white-color);
    z-index: 1002;

    p {
      margin: 14px 14px 10px 14px;
      cursor: pointer;
      text-align: left;
      color: var(--grey-1-color);

      &:last-child {
        margin: 14px
      }

      &:hover {
        transition: all 0.1s ease;
        color: var(--primary-color);
      }
    }
  }

}
</style>